<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Usuários</a>
        </li>
      </ul>
    </nav>

    <button class="button m-b-md" @click="add">Adicionar</button>

    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="getUser">
          <b-field label="Login">
            <Multiselect
              v-model="query.login"
              id="ajax2"
              label="username"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList2"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch2"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <b-field label="Grupo" v-if="hasProfile('admin')">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">
                {{
                team.name
                }}
              </option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <button class="button is-primary">Buscar</button>
          <button
            type="button"
            class="button is-danger m-l-sm"
            @click="query = {}, users = []"
          >Limpar</button>
        </form>
      </div>
    </b-collapse>

    <h1>Total: {{ total }}</h1>

    <b-table
      :data="filter"
      current-page.sync="1"
      default-sort-direction="asc"
      default-sort="user.first_name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot-scope="props">
        <b-table-column field="nome" label="Nome" sortable>
          {{
          props.row.name
          }}
        </b-table-column>
        <b-table-column field="username" label="Login" sortable>
          {{
          props.row.username
          }}
        </b-table-column>
        <b-table-column field="teams[0]._id" label="Grupo" sortable>
          {{
          props.row.teams[0] ? props.row.teams[0].name : ''
          }}
        </b-table-column>
        <b-table-column field="cpf" label="CPF" sortable>
          {{
          props.row.cpf
          }}
        </b-table-column>
        <b-table-column field="createdAt" label="Data criação" sortable>
          {{
          new Date(props.row.createdAt).toLocaleDateString('pt-BR')
          }}
        </b-table-column>
        <b-table-column field="updatedAt" label="Data atualização" sortable>
          {{
          new Date(props.row.updatedAt).toLocaleDateString('pt-BR')
          }}
        </b-table-column>
      </template>

      <template v-if="hasProfile('admin')" slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <button class="button is-primary m-r-sm" type="button" @click="edit(props)">Editar</button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <div class="custom">
      <b-pagination
        class="m-t-md"
        :total="total"
        :current.sync="current"
        :simple="false"
        :rounded="false"
        :per-page="perPage"
        aria-next-label="Próxima pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Page"
        aria-current-label="Pagina atual"
      ></b-pagination>
    </div>

    <b-modal :active.sync="isCardModalActive" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-on:submit.prevent="addUser">
              <b-field label="NOME">
                <b-input type="text" v-model="user.name"></b-input>
              </b-field>

              <b-field label="Perfil">
                <b-select v-model="user.roles" placeholder="Perfil">
                  <option value="student">Estudante</option>
                  <option value="instructor">Instrutor</option>
                  <option value="admin">Administrador</option>
                  <option value="manager">Gerente</option>
                </b-select>
              </b-field>

              <b-field label="Grupo">
                <b-select v-model="user.teams" placeholder="Grupo">
                  <option v-for="team in teams" :key="team._id" :value="team._id">{{ team.name }}</option>
                </b-select>
              </b-field>

              <b-field label="Login" v-if="id">
                <b-input type="text" v-model="user.username"></b-input>
              </b-field>

              <b-field label="Login" v-else>
                <b-select v-model="login" placeholder="Login">
                  <option value="email">Email</option>
                  <option value="cpf">CPF</option>
                </b-select>
              </b-field>

              <b-field label="Curriculo" v-if="user.roles === 'instructor'">
                <b-input type="textarea" v-model="user.extra.description"></b-input>
              </b-field>

              <div class="is-flex-desktop">
                <b-field label="CPF" class="m-r-md" :type="erroForm.cpf">
                  <b-input v-mask="['###.###.###-##']" v-model="user.cpf"></b-input>
                </b-field>

                <b-field label="Data de Nascimento">
                  <b-datepicker
                    :month-names="datePicker[0]"
                    :day-names="datePicker[1]"
                    icon="calendar-alt"
                    v-model="user.birthday"
                  ></b-datepicker>
                </b-field>
              </div>

              <b-field label="CEP" :type="erroForm.cep">
                <b-input type="text" v-mask="['#####-###']" maxlength="9" v-model="cep"></b-input>
              </b-field>

              <div class="is-flex-desktop">
                <b-field label="ESTADO" class="m-r-md" v-if="user.state">
                  <b-input type="text" v-model="user.state"></b-input>
                </b-field>

                <b-field label="CIDADE" v-if="user.city">
                  <b-input type="text" v-model="user.city"></b-input>
                </b-field>
              </div>

              <b-field label="ENDREÇO" v-if="user.city">
                <b-input type="text" v-model="user.address"></b-input>
              </b-field>

              <b-field label="BAIRRO" v-if="user.city">
                <b-input type="text" v-model="user.neighborhood"></b-input>
              </b-field>

              <b-field label="NÚMERO" v-if="user.city">
                <b-input type="number" v-model="user.addressNumber"></b-input>
              </b-field>

              <div class="is-flex-desktop">
                <b-field label="TELEFONE">
                  <b-input
                    class="m-r-md"
                    type="text"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="user.phone"
                  ></b-input>
                </b-field>

                <b-field label="CELULAR" class="m-b-sm">
                  <b-input
                    type="text"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="user.cellPhone"
                  ></b-input>
                </b-field>
              </div>

              <img v-if="img[1]" :src="img[1]" alt="Imagem upada" />
              <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>

              <b-upload v-model="img[0]" @input="upload($event)">
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span>Adicionar Arquivo</span>
                </a>
              </b-upload>

              <b-field label="Email" :type="erroForm.email">
                <b-input type="email" v-model="user.email"></b-input>
              </b-field>

              <b-field label="Senha">
                <b-input type="password" v-model="user.password"></b-input>
              </b-field>

              <button class="button is-info">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Axios from "axios";
import { debounce } from "lodash";

import { hasProfile } from "@/global/auth";
import { mask } from "vue-the-mask";
import { validateCPF } from "../../../assets/js/validatorCpf";
import Multiselect from "vue-multiselect";

export default {
  directives: { mask },
  components: { Multiselect },
  data() {
    return {
      img: [],
      me: null,
      teams: [],
      hasProfile,
      isLoading: false,
      isCardModalActive: false,
      isCardModalActive2: false,
      search: null,
      query: {},
      login: null,
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ],
      user: {
        username: "",
        name: "",
        password: "",
        cpf: "",
        email: "",
        birthday: null,
        state: "",
        city: "",
        addres: "",
        zipCode: "",
        phone: "",
        cellPhone: "",
        image: null,
        roles: "",
        teams: "",
        neighborhood: "",
        extra: {
          description: ""
        }
      },
      cep: "",
      erroForm: {
        cep: "",
        cpf: "",
        email: ""
      },
      data: [],
      cursos: [],
      id: "",
      total: "",
      current: 1,
      perPage: "",
      users: "",
      isLoadingMultiSelect: false,
      searchList: [],
      searchList2: []
    };
  },
  methods: {
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${this.me ? "users/team/" + this.me.teams[0] : "users"}?name=${
            arguments[0]
          }`
        )
          .then(res => {
            this.searchList = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    getBySearch2: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${
            this.me ? "users/team/" + this.me.teams[0] : "users"
          }?username=${arguments[0]}`
        )
          .then(res => {
            this.searchList2 = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    add() {
      this.user = {};
      let temp = {
        extra: {
          description: ""
        },
        image: ""
      };
      this.user = temp;
      this.cep = "";
      this.id = null;
      this.isCardModalActive = true;
    },
    edit(data) {
      this.user.neighborhood = data.row.neighborhood;
      this.user.address = data.row.address;
      this.user.cellPhone = data.row.cellPhone;
      this.user.city = data.row.city;
      this.user.cpf = data.row.cpf;
      this.user.email = data.row.email;
      this.user.addressNumber = data.row.addressNumber;
      this.user.name = data.row.name;
      this.user.phone = data.row.phone;
      this.user.state = data.row.state;
      this.user.username = data.row.username;
      this.user.zipCode = data.row.zipCode;
      this.user.birthday = data.row.birthday
        ? new Date(data.row.birthday)
        : null;
      this.user.roles = data.row.roles[0] ? data.row.roles[0] : data.row.roles;
      if (data.row.teams && data.row.teams[0])
        this.user.teams = data.row.teams[0]._id;
      else this.user.teams = null;
      this.cep = data.row.zipCode;
      this.user.image = data.row.image;
      this.img[1] = data.row.image;
      this.id = data.row._id;
      this.isCardModalActive = true;
      delete this.user.createdAt;
      delete this.user.updatedAt;
    },
    remove(data) {
      Axios.delete(`/v1/users/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getUser();
            this.$buefy.notification.open({
              message: "Usuário exclusernameo com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    addUser() {
      this.erroForm = {
        cep: "",
        cpf: "",
        email: ""
      };
      this.user.cpf = this.toNumber(this.user.cpf);
      if (validateCPF(this.user.cpf)) {
        if (!this.user.password) delete this.user.password;
        if (!this.id) this.user.username = this.user.email;
        if (this.login === "cpf") this.user.username = this.user.cpf;
        this.user.cellPhone
          ? (this.user.cellPhone = this.toNumber(this.user.cellPhone))
          : "";
        this.user.phone
          ? (this.user.phone = this.toNumber(this.user.phone))
          : "";
        this.user.addressNumber = this.user.addressNumber * 1;
        this.user.roles = [this.user.roles];
        if (!this.user.image) delete this.user.image;

        let temp = !this.id
          ? Axios.post(`/v1/users`, this.user)
          : Axios.put(`/v1/users/${this.id}`, this.user);
        temp
          .then(data => {
            if (data.data) {
              this.getUser();
              if (this.id) {
                this.$buefy.notification.open({
                  message: "Usuário atualizado com sucesso!",
                  type: "is-success"
                });
              } else {
                this.$buefy.notification.open({
                  message: "Usuário criado com sucesso!",
                  type: "is-success"
                });
              }
              this.isCardModalActive = false;
            }
          })
          .catch(erro => {
            for (const e of erro.response.data.error) {
              if (
                e.message[0].includes("cpf") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("CPF já utlizado!");
                this.erroForm.cpf = "is-danger";
              } else if (
                e.message[0].includes("uid") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              } else if (
                e.message[0].includes("email") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              }
            }
          });
      } else {
        this.erroForm.cpf = "is-danger";
        this.$buefy.dialog.alert("CPF inválido!");
      }
    },
    getUser() {
      window.scrollTo(0, 1);
      Axios.get(
        `${this.me ? "/v1/users/team/" + this.me.teams[0] : "/v1/users"}?page=${
          this.current
        }${this.users._id ? "&_id=" + this.users._id : ""}${
          this.query.team ? "&teams=" + this.query.team : ""
        }${this.query.login ? "&username=" + this.query.login.username : ""}`
      )
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            this.total = data.data.total;
            this.perPage = data.data.limit;
            this.data = data.data.data;
          }
          this.$forceUpdate(this.data);
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este usuário?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    getCep() {
      let temp = Axios.defaults.headers;
      delete Axios.defaults.headers;
      Axios({
        method: "GET",
        url: `https://viacep.com.br/ws/${this.cep}/json/`,
        headers: {}
      })
        .then(data => {
          var address = data.data;
          this.user.zipCode = this.toNumber(address.cep);
          this.user.address = address.logradouro;
          this.user.city = address.localidade;
          this.user.state = address.uf;
          this.user.neighborhood = address.bairro;
          this.erroForm.cep = null;
          Axios.defaults.headers = temp;
        })
        .catch(erro => {
          console.error(erro);
          this.erroForm.cep = "is-danger";
          this.$buefy.dialog.alert("CEP inválido!");

          Axios.defaults.headers = temp;
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then(data => {
          if (data.data) {
            this.cursos = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then(data => {
          this.me = data.data;
          this.getUser();
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then(data => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    toNumber(data) {
      return data.replace(/[^\d]+/g, "");
    },
    upload(data) {
      this.isLoading = true;
      const fd = new FormData();
      fd.append("file", data, data.name);
      Axios.post(`/v1/uploads/`, fd)
        .then(res => {
          this.img[1] = res.data.location;
          this.user.image = res.data.location;
          this.$forceUpdate(this.img);
          this.isLoading = false;
        })
        // eslint-disable-next-line
        .catch(err => {
          this.$buefy.dialog.alert("Erro ao enviar arquivo!");
        });
    }
  },
  watch: {
    cep(cep) {
      if (cep.length == 9) {
        this.getCep();
      }
    },
    current() {
      this.getUser();
    }
  },
  computed: {
    filter: function() {
      if (!this.data.length) {
        return [];
      }

      return this.data.filter(item => {
        let props = Object.values(item);
        let search = new RegExp(this.search, "i");
        return props.some(
          prop =>
            !this.search ||
            (typeof prop === "string"
              ? prop.match(search)
              : typeof prop !== "number"
              ? ""
              : prop.toString(10).match(search))
        );
      });
    }
  },
  mounted() {
    this.hasProfile("manager") ? this.getMe() : this.getUser();
    this.getCourse();
    if (this.hasProfile("admin")) this.getTeam();
  }
};
</script>

<style lang="scss" scoped>
.is-flex-desktop {
  .field {
    width: 100%;
  }
}
img {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 10px;
}
.custom {
  display: flex;
  justify-content: flex-end;
}
</style>
